import React from "react";
import AchivementArea from "../../common/AchivementArea";
import Breadcrumb from "../../common/Breadcrumb";
import JoinUs from "../../common/JoinUs"; 
import AboutWrap from "./AboutWrap";
import { Helmet } from 'react-helmet';

function AboutPage() {
  return (
    <>
     <Helmet>
        <title>About Us | Greenhood Tech – Experts in IT Solutions</title>
        <meta
          name="description"
          content="Learn about Greenhood Tech, a leading IT company providing mobile app development, web development, and e-commerce solutions."
        />
        <meta name="keywords" content="IT company, mobile app development, web development, digital transformation, business solutions" />
        <meta property="og:title" content="About Greenhood Tech – Experts in IT Solutions" />
        <meta property="og:description" content="Learn about Greenhood Tech, a leading IT company providing mobile app development, web development, and e-commerce solutions." />
      </Helmet>

      <Breadcrumb name="About Us" />
      <AboutWrap />
      <AchivementArea />
      {/* <Testimonial /> */}
      <JoinUs padding="pt-120" />
      {/* <Sponsor /> */}
    </>
  );
}

export default AboutPage;
