import React from "react";
import Breadcrumb from "../../common/Breadcrumb"; 
import JoinUs from "../../common/JoinUs"; 
import ServiceWrap from "./ServiceWrap";
import { Helmet } from 'react-helmet';

function ServicePage() {
  return (
    <>
     <Helmet>
        <title>Our Services | Mobile App Development, Web Development & E-Commerce Solutions</title>
        <meta
          name="description"
          content="Greenhood Tech offers mobile app development (iOS & Android), web development, and e-commerce solutions to accelerate your business growth."
        />
        <meta name="keywords" content="mobile app development, iOS app development, Android app development, web development, e-commerce solutions" />
        <meta property="og:title" content="Our Services | Mobile App Development, Web Development & E-Commerce Solutions" />
        <meta property="og:description" content="Greenhood Tech offers mobile app development (iOS & Android), web development, and e-commerce solutions to accelerate your business growth." />
      </Helmet>
    {/* <SocialMedia/> */}
      <Breadcrumb name="Our Services" />
      <ServiceWrap />
      {/* <PricingCard /> */}
      <JoinUs padding="pt-120" />
      {/* <Sponsor /> */}
    </>
  );
}

export default ServicePage;
