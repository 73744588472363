import React from "react";
import Banner from "./Banner"; 
import JoinUs from "../../common/JoinUs";
import HomeoneLetestWork from "./HomeoneLetestWork";
import HomeoneService from "./HomeoneService"; 
import AchivmentArea from "../../common/AchivementArea";
import AboutArea from "./AboutArea";
import TechStack from "./TechStack";
import { Helmet } from 'react-helmet';

function HomePage() {
  return (
    <>
     <Helmet>
        <title>Greenhood Tech | IT Solutions & Services | Mobile App Development, Web Development & E-Commerce</title>
        <meta
          name="description"
          content="Greenhood Tech offers mobile app development, web development, and e-commerce solutions to help businesses grow online."
        />
        <meta name="keywords" content="IT solutions, mobile app development, web development, e-commerce solutions" />
        <meta property="og:title" content="Greenhood Tech | IT Solutions & Services" />
        <meta property="og:description" content="Greenhood Tech offers mobile app development, web development, and e-commerce solutions to help businesses grow online." />
        <meta property="og:image" content="	https://greenhoodtech.com/images/icon/logo.png" />
      </Helmet>
      <Banner />
      <HomeoneService />
      <HomeoneLetestWork />
      <AboutArea />
      <AchivmentArea />
      {/* <TeamArea /> */}
      {/* <BlogArea /> */}
      {/* <TestimonialOne /> */}
      <JoinUs padding="pt-120" />
      {/* <Sponsor /> */}
      <TechStack  /> 
    </>
  );
}

export default HomePage;
