import React from "react";
import Breadcrumb from "../../common/Breadcrumb";
import ContactWrap from "./ContactWrap";
import { Helmet } from 'react-helmet';
function ContaceUsPage() {
  return (
    <>
     <Helmet>
        <title>Contact Us | Greenhood Tech</title>
        <meta
          name="description"
          content="Get in touch with Greenhood Tech for your mobile app development, web development, and e-commerce solution needs."
        />
        <meta name="keywords" content="contact Greenhood Tech, mobile app development, web development, e-commerce solutions" />
        <meta property="og:title" content="Contact Us | Greenhood Tech" />
        <meta property="og:description" content="Contact Greenhood Tech for your mobile app development, web development, and e-commerce solution needs." />
      </Helmet>
      <Breadcrumb name="Contact Us" />
      <ContactWrap />
    </>
  );
}

export default ContaceUsPage;
