import React, { useState,useEffect  } from "react";
import { Link } from "react-router-dom"; 
function Footer() {
  const happytoHelp={ 
    marginTop: "23px", 
    borderRadius: "5px",
    padding: "20px",
    border: "1px solid #494949",
  }
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  const year=new Date().getFullYear();
  return (
    <>
      <footer className="footer-section">
        <div className="container">
          <div className="footer-top-area">
            <div className="row d-flex justify-content-sm-start justify-content-center gy-5">
              <div className="col-lg-5 col-md-6 text-sm-start">
                <div className="footer-left-address">
                  <div   className='  w-100' onClick={scrollTop}>
                    <img style={{width:'200px'}}
                      src={process.env.PUBLIC_URL + "/images/icon/footer-logo.png"}
                      className="footer-logo mb-4"
                      alt="images"
                    />
                  </div>
                  <ul className="address-list">
                    <li>
                      <Link
                        onClick={scrollTop}
                        to="mailto:info@greenhoodtech.com"
                      >
                        Email: info@greenhoodtech.com
                      </Link>
                    </li>
                    <li>
                      <Link onClick={scrollTop} to={"#"}>
                        Address:1 Hariraj Street,Perumalpuram,<br/> Tirunelveli-627007
                      </Link>
                    </li>
                    <li>
                      <a href="tel:9146223582780">Phone: +91 462-23582780 &nbsp; / </a>
                      <a href="tel:917904901100">&nbsp;+91 7904901100</a>
                    </li>
                  </ul>
                  {/* <div className="newsletter-area">
                    <h5>Get The Updates</h5>
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div className="form-inner">
                        <input type="text" placeholder="Type your Email :" />
                        <Link
                          onClick={scrollTop}
                          to={"#"}
                          className="newsletter-btn hover-btn"
                        >
                          <span>
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/images/icon/btn-arrow.svg"
                              }
                              alt="images"
                            />
                          </span>
                        </Link>
                      </div>
                      <p>We only send interesting and relevant emails.</p>
                    </form>
                  </div> */}
                </div>
              </div>
              <div className="col-lg-7 col-md-6">
                <div className="footer-right-area">
                  <div className="row g-4">
                    <div className="col-lg-3 col-md-6 col-sm-6 d-flex justify-content-sm-start">
                      <div className="footer-item text-sm-start">
                        <h5 className="footer-title">Explore On</h5>
                        <ul className="footer-link">
                          <li>
                            <Link
                              to={`${process.env.PUBLIC_URL}/`}
                              onClick={scrollTop}
                            >
                              Home
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={`${process.env.PUBLIC_URL}/about-us`}
                              onClick={scrollTop}
                            >
                              About Us
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={`${process.env.PUBLIC_URL}/project`}
                              onClick={scrollTop}
                            >
                              Products
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={`${process.env.PUBLIC_URL}/service`}
                              onClick={scrollTop}
                            >
                              services
                            </Link>
                          </li>
                          <li>
                            <Link to={`${process.env.PUBLIC_URL}/contact-us`}
                              onClick={scrollTop}>Contact Us</Link>
                          </li>
                          {/* <li><a href="#">List With Sidebar</a></li> */}
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 d-flex justify-content-sm-start">
                      <div className="footer-item text-sm-start">
                        <h5 className="footer-title">Our Services</h5>
                        <ul className="footer-link">
                          <li>
                            <Link
                              // to={`${process.env.PUBLIC_URL}/service-details`}
                              // onClick={scrollTop}
                            >
                               Mobile Apps
                            </Link>
                          </li>
                          <li>
                            <Link
                              // to={`${process.env.PUBLIC_URL}/service-details`}
                              // onClick={scrollTop}
                            >
                             E-commerce
                            </Link>
                          </li>
                          <li>
                            <Link
                              // to={`${process.env.PUBLIC_URL}/service-details`}
                              // onClick={scrollTop}
                            >
                              Web Developement
                            </Link>
                          </li>
                          <li>
                            <Link
                              // to={`${process.env.PUBLIC_URL}/service-details`}
                              // onClick={scrollTop}
                            >
                              SaaS
                            </Link>
                          </li>
                          <li>
                            <Link
                              // to={`${process.env.PUBLIC_URL}/service-details`}
                              // onClick={scrollTop}
                            >
                             Business Websites
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-12 d-flex justify-content-sm-start">
                      <div className="footer-item text-sm-start">
                        <h5 className="footer-title">Join Our Community</h5>
                        <div className="mt-0 footer-social-area d-flex justify-content-sm-start justify-content-center align-items-center flex-wrap">
                        {/* <h5>Join Our Community</h5> */}
                        <ul className="footer-social-list">
                           
                          <li>
                            <a
                              rel="noopener noreferrer"
                              href="https://www.facebook.com/people/Greeenhoodtech/61558435622061/"
                              target="_blank"
                            >
                              <i className="bx bxl-facebook" />
                            </a>
                          </li>
                          {/* <li>
                            <a
                              rel="noopener noreferrer"
                              href="https://www.pinterest.com/"
                              target="_blank"
                            >
                              <i className="bx bxl-pinterest-alt" />
                            </a>
                          </li> */}
                          <li>
                            <a
                              rel="noopener noreferrer"
                              href="https://www.instagram.com/greenhoodtech24/"
                              target="_blank"
                            >
                              <i className="bx bxl-instagram" />
                            </a>
                          </li>
                          <li>
                            <a
                              rel="noopener noreferrer"
                              href="https://www.linkedin.com/in/green-hood-tech-538a0b307"
                              target="_blank"
                            >
                              <i className="bx bxl-linkedin" />
                            </a>
                          </li>
                        </ul>
                         
                      </div>
                        <div style={happytoHelp} >
                        <h5 className=" m-0 text-center text-white">Happy to Help</h5>
                        <div className="mt-3 footer-social-area d-flex justify-content-sm-start justify-content-center align-items-center flex-wrap">
                        
                        <ul className="footer-social-list w-100 justify-content-evenly ">
                        <li className="me-0">
                            <a
                              rel="noopener noreferrer"
                              href="tel:917904901100"
                              target="_blank"
                            >
                              <i className="bx bxs-phone-call bx-tada" />
                            </a>
                          </li>
                          <li className="me-0">
                            <a
                              rel="noopener noreferrer"
                              href="https://wa.me/917904901100"
                              target="_blank"
                            >
                              <i className="bx bxl-whatsapp bx-tada" />
                            </a>
                          </li>
                           
                          
                          
                        </ul>
                         
                      </div>
                        </div>
                      </div>
                     
                    </div>
                  </div>
                  {/* <div className="row">
                    <div className="col-md-12">
                      <div className="footer-social-area d-flex justify-content-sm-start justify-content-center align-items-center flex-wrap">
                        <h5>Join Our Community</h5>
                        <ul className="footer-social-list">
                          <li>
                            <a
                              rel="noopener noreferrer"
                              href="https://www.twitter.com/"
                              target="_blank"
                            >
                              <i className="bx bxl-twitter" />
                            </a>
                          </li>
                          <li>
                            <a
                              rel="noopener noreferrer"
                              href="https://www.facebook.com/"
                              target="_blank"
                            >
                              <i className="bx bxl-facebook" />
                            </a>
                          </li>
                          <li>
                            <a
                              rel="noopener noreferrer"
                              href="https://www.pinterest.com/"
                              target="_blank"
                            >
                              <i className="bx bxl-pinterest-alt" />
                            </a>
                          </li>
                          <li>
                            <a
                              rel="noopener noreferrer"
                              href="https://www.instagram.com/"
                              target="_blank"
                            >
                              <i className="bx bxl-instagram" />
                            </a>
                          </li>
                          <li>
                            <a
                              rel="noopener noreferrer"
                              href="https://in.linkedin.com/"
                              target="_blank"
                            >
                              <i className="bx bxl-linkedin" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom-area ">
            <div className="row">
              <div className="col-lg-5 text-lg-start text-center">
                <div className="footer-bottom-left">
                  <a
                    onClick={scrollTop}
                    rel="noopener noreferrer"
                    // href="https://www.egenslab.com/"
                    // target="_blank"
                  >
                    <h6 className="mb-0">
                      © {year} Green Hood Tech. All Rights Reserved
                      {/* Copyright 2024 © Green Hood Tech.All Rights Reserved */}
                      {/* | Design By <span>Egens Lab</span> */}
                    </h6>
                  </a>
                </div>
              </div>
              {/* <div className="col-lg-7 text-lg-end text-center">
                <div className="footer-bottom-right">
                  <Link onClick={scrollTop} to={"#"}>
                    <h6 className="mb-0">Privacy Policy | Terms of Use</h6>
                  </Link>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </footer>

      <div className="scroll-btn show">
        <ScrollButton></ScrollButton>
         
      </div>
      <div className="scroll-btn show" style={{bottom:'20px'}}>
         
        <ScrollDownButton></ScrollDownButton>
      </div>
    </>
  );
}
const ScrollButton = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 800) {
      setVisible(true);
    } else if (scrolled <= 100) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <i
      className="bx bxs-up-arrow-circle"
      onClick={scrollToTop}
      style={{ display: visible ? "inline" : "none" }}
    ></i>
  );
};


const ScrollDownButton = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    
    if (scrolled > 400 && scrolled + windowHeight < documentHeight) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisible);
    return () => {
      window.removeEventListener("scroll", toggleVisible);
    };
  }, []);

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  return (
    <i
      className="bx bxs-down-arrow-circle"
      onClick={scrollToBottom}
      style={{ display: visible ? "block" : "none" }}
    ></i>
  );
};



export default Footer;
