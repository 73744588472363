import React from "react";
import Breadcrumb from "../../common/Breadcrumb";
import JoinUs from "../../common/JoinUs";
import { Helmet } from 'react-helmet';
import ProjectWrap from "./ProjectWrap";

function ProjectPage() {
  return (
    <>
      <Helmet>
        <title>Our Products | Mobile App Solutions, Web Development & E-Commerce</title>
        <meta
          name="description"
          content="Explore Greenhood Tech’s innovative products, including mobile apps (iOS & Android), web development, and e-commerce solutions."
        />
        <meta name="keywords" content="mobile app solutions, web development, e-commerce products, IT products" />
        <meta property="og:title" content="Our Products | Mobile App Solutions & Web Development" />
        <meta property="og:description" content="Discover Greenhood Tech’s innovative products, including mobile apps, web development solutions, and e-commerce platforms." />
      </Helmet>
      <Breadcrumb name="Our Products" />
      <ProjectWrap />
      <JoinUs padding="pt-120" />
      {/* <Sponsor /> */}
    </>
  );
}

export default ProjectPage;
